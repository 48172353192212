@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Play:wght@400;700&display=swap');

/* Existing Styles */

.home-page {
    min-height: 90vh;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merriweather', serif;
    /*padding: 2rem 2rem 0;*/
    box-sizing: border-box;
    flex-direction: column; /* Ensure vertical stacking */
}

.content {
    text-align: left;
    max-width: 1000px;
    width: 100%;
    padding: 2rem 2rem;
}

.name {
    font-family: 'Merriweather', serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
}

.tagline {
    font-family: 'Merriweather', serif;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
}

.bio {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    /*margin-bottom: 2rem;*/
}

.social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #ccc;
    cursor: pointer;
}

.navigation {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.nav-link {
    font-family: 'Barlow Condensed', sans-serif;
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border: 2px solid #fff;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.home-page.dark {
    background-color: #000;
    color: #fff;
}

.home-page.less-dark {
    background-color: #222;
    color: #ccc;
}

a {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Courier New', monospace;
    font-size: 1.2rem;
}

a:hover {
    color: #ffffff;
    text-decoration: line-through;
}

.info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
}

.research-interests,
.education {
    flex: 1;
    padding: 1rem;
}

.research-interests h2,
.education h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.research-interests h2 svg,
.education h2 svg {
    margin-right: 0.5rem;
}

.research-interests ul,
.education ul {
    list-style-type: none;
    padding: 0;
}

.research-interests li,
.education li {
    margin-bottom: 0.5rem;
}

.education li i {
    font-style: italic;
}

@media screen and (max-width: 768px) {
    .info-container {
        flex-direction: column;
    }
}

.education .description {
    color: #cccccc;
    font-size: smaller;
}

.intro-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.intro-text {
    flex: 1;
    padding-right: 2rem;
}

.intro-image {
    flex: 1;
    text-align: center;
}

.intro-image img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

.image-caption {
    font-style: italic;
}

@media screen and (max-width: 600px) {
    .container {
        flex-direction: column;
    }

    .intro-text {
        padding-right: 0;
        margin-bottom: 2rem;
    }

    .content {
        max-width: 100%;
    }

    .name {
        font-size: 2.5rem;
    }

    .tagline {
        font-size: 1.2rem;
    }

    .bio {
        font-size: 1rem;
    }

    .nav-link {
        margin-bottom: 1rem;
    }

    .home-page {
        padding: 0;
    }

    .tagline {
        font-size: 0.9rem;
    }
}

/* Minimal Styles for Projects Section */
.projects {
    width: 100%;
    padding: 2rem 0;
}

.projects h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
}

.project-card {
    background-color: #222;
    border: 1px solid #444;
    border-radius: 6px;
    width: 280px;
    padding: 1rem;
    color: #ccc;
}

.project-card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #fff;
}

.project-links a {
    margin-right: 0.5rem;
    color: #1e90ff;
    font-size: 0.9rem;
    text-decoration: none;
}

.project-links a:hover {
    text-decoration: underline;
}

