@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&family=Bebas+Neue&family=Montserrat:wght@400;700&family=Oswald:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Play:wght@400;700&display=swap');

.lastfm-stats {
    min-height: 100vh;
    padding: 2rem;
    background-color: #000;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.lastfm-stats h2 {
    font-family: 'Merriweather', sans-serif;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.lastfm-stats h3 {
    font-family: 'Merriweather', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /*text-transform: uppercase;*/
}

.stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stats-section {
    flex-basis: calc(33.33% - 1rem);
    margin-bottom: 2rem;
}

.stats-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.album-cover {
    position: relative;
    margin-bottom: 1.5rem;
}

.album-cover img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.album-name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0 0 4px 4px;
}

.lastfm-stats ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.lastfm-stats li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.nav-link {
    font-family: 'Barlow Condensed', sans-serif;
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    border: 2px solid #fff;
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-block;
    margin-bottom: 2rem;
}

.nav-link:hover {
    background-color: #fff;
    color: #000;
}

.last-updated {
    font-size: 1rem;
    color: #ccc;
    margin-top: 2rem;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .stats-section {
        flex-basis: 100%;
    }
}

.lastfm-stats h3 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.album-name {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 1rem;
    font-weight: 700;
}

.lastfm-stats li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.lastfm-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.lastfm-stats h2 {
    text-align: center;
}

.lastfm-stats .stats-container {
    max-width: 1200px;
    width: 100%;
}


@media screen and (max-width: 600px) {
    .lastfm-stats h2 {
        font-size: 1.3rem;
    }
}
