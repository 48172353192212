@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Merriweather', serif;
  background-color: #000;
  color: #fff;
}

h1, h2, h3 {
  font-family: 'Merriweather', sans-serif;
  font-weight: 700;
}
