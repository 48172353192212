.contact {
    min-height: 90vh;
    background-color: #000; /* Matches the home-page background */
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;
}

.contact h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.contact p {
    font-size: 1rem;
    margin-bottom: 2rem;
    max-width: 600px;
}

.contact-button {
    padding: 0.75rem 1.5rem;
    background-color: #1e90ff; /* Button background color */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #63b3ed; /* Button hover color */
}

