.navigation {
    background-color: #1a1a1a;
    padding: 1rem;
    text-align: right;
}

.navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation li {
    display: inline-block;
    margin-left: 1rem;
}

.navigation a {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Courier New', monospace;
    font-size: 1.2rem;
}

.navigation a:hover {
    color: #ffffff;
    text-decoration: line-through;
}

@media screen and (max-width: 600px) {
    .navigation {
        padding: 0.3rem;
    }
}
