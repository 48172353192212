.publications-page {
    min-height: 90vh;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merriweather', sans-serif;
    padding: 2rem;
    box-sizing: border-box;
}

.publications-content {
    max-width: 800px;
    width: 100%;
    padding: 2rem;
}

.publication {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #1a1a1a;
    border-radius: 4px;
}

.publication h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.publication p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
}

a:hover {
    color: #ffffff;
    text-decoration: line-through;
    cursor: pointer;
}

.publications,
.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.publications h2,
.contact-content h2 {
    text-align: center;
}

.publications .publication,
.contact-content {
    max-width: 900px;
    width: 100%;
}

.publication a {
    font-size: 0.8rem;
    word-wrap: break-word;
}

@media screen and (max-width: 600px) {
    .publications-page {
        padding: 0;
    }
    .publication h3 {
        font-size: 1rem;
    }
}

.publications-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.publication {
    margin-bottom: 30px;
}

.publication h3 {
    margin-bottom: 10px;
}

.publication p {
    margin: 5px 0;
}

.publication-links {
    margin-top: 10px;
    text-align: center;
}

.publication-links a {
    margin-right: 15px;
    color: #ffffff;
}


.summary-section {
    margin-top: 15px;
    text-align: center;
}

.summary-text-section {
    margin-top: 15px;
    text-align: left;
}

.summary-toggle {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.6s ease;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
}

.summary-toggle:hover {
    text-decoration: line-through;
}

.summary-content {
    margin-top: 15px;
    padding: 15px;
    background-color: #1a1a1a;
    border-radius: 4px;
    border: 3px solid #333;
    text-align: left;
}

.summary-content h4 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 1.1em;
}

.summary-content .summary-section {
    margin-bottom: 15px;
}

.summary-content h5 {
    margin-top: 0;
    margin-bottom: 8px;
    color: #ffffff;
    font-size: 1em;
}

.summary-content p {
    margin: 0;
    line-height: 1.5;
    color: #ffffff;
    font-size: 0.9rem;
}

.summary-text-section ol,
.summary-text-section ul
{
    font-size: 0.9rem;
    padding-left: 15px;
}

.summary-text-section p {
    font-size: 0.9rem;
}

.summary-toggle .fa-spinner {
    margin-right: 5px;
}
